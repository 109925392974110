.image {
  display: block;
  width: 100%;
  height: auto;
  object-fit: cover;
}

/* Bootstrap lg, and +  */
.image.twoPerRow {
  aspect-ratio: 7/4;
}
.image.threePerRow {
  aspect-ratio: 21/12;
}
.image.fourPerRow {
  aspect-ratio: 4/3;
}
.image.fivePerRow {
  aspect-ratio: 1/1;
}
.image.sixPerRow {
  aspect-ratio: 1/1;
}
/*  */

/* Bootstrap breakpoint */
@media only screen and (max-width: 1200px) {
  .image.twoPerRow {
    aspect-ratio: 11/6
  }
}

/* Breakpoint */
@media only screen and (max-width: 1190px) {
  .image.threePerRow {
    aspect-ratio: 12/8;
  }
  .image.fourPerRow {
    aspect-ratio: 11/10;
  }
}


/* Bootstrap up to lg */
@media only screen and (max-width: 992px) {
  .image.twoPerRow {
    aspect-ratio: 9/5;
  }
  .image.threePerRow {
    aspect-ratio: 11/8;
  }
  .image.fourPerRow {
    aspect-ratio: 1/1;
  }
}

/* Bootstrap up to md */
@media only screen and (max-width: 768px) {
  .image.threePerRow {
    aspect-ratio: 5/4;
  }
  .image.fourPerRow {
    aspect-ratio: 5/4;
  }
  .image.fivePerRow {
    aspect-ratio: 5/4;
  }
  .image.sixPerRow {
    aspect-ratio: 5/4;
  }
}

/* Bootstrap up to sm */
@media only screen and (max-width: 576px) {
  .image.twoPerRow {
    aspect-ratio: 9/6;
  }
  .image.threePerRow {
    aspect-ratio: 9/6;
  }
  .image.fourPerRow {
    aspect-ratio: 9/6;
  }
  .image.fivePerRow {
    aspect-ratio: 9/6;
  }
  .image.sixPerRow {
    aspect-ratio: 9/6;
  }
}

.subHeading {
  font-size: 12px !important;
}

.caption {
  margin-top: 10px;
}

.captionHeading {
  font-size: 18px !important;
  font-weight: bold !important;
}

.captionDescription {
  font-size: 14px;
}

.rdsImageGalleryTrayHeading {
  color: var(--color-primary);
  font-size: 0.8rem;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
}

.rdsImageGalleryTraySubHeading {
  text-align: center;
  color: var(--color-gray-black);
  font-family: var(--font-medium);
  font-size: 2.5rem;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 0.5rem;
  margin-top: 0;
}

.rdsImageGalleryTraydescription {
  text-align: left;
  color: var(--color-gray-black);
  font-family: var(--font-medium);
  font-size: 0.9rem;
  line-height: 1.2;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}
