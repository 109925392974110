@import "./rds-variable.css";
@import "./rds-common.css";

.footerContainer {
  background-color: var(--color-gray-black);
  color: var(--color-white);
  position: relative;
}

.footerContainer a {
  color: var(--color-light-gray);
  text-decoration: none;
}

.mobileHr {
  display: none;
}

.mobileHr,
.hr {
  background: var(--neutral-600, #626262);
  height: 1px;
  width: 100%;
}

@media screen and (min-width: 768px) {
  .displayStyles {
    display: flex;
    justify-content: flex-end;
  }
}

@media (max-width: 768px) {
  .mobileHr {
    display: inline-block;
  }
}
