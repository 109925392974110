.commonMenuContainer {
  width: 100%;
  background-color: var(--neutral-100);
}

.commonMenuList {
  margin-bottom: 0;
  padding: 1rem 1rem 1rem 1.5rem;
  margin-bottom: 0;
}

.commonMenuList li {
  border-bottom: none;
}