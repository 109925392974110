.menuListItem {
    border-bottom: 1px solid var(--color-light-gray);
    padding: 12px 0;
    position: relative;
    list-style: none;
  }
  
  .menuListItem:last-of-type {
    border-bottom: none;
  }

  .menuElementRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .menuElement {
    text-decoration: none;
    font-size: 0.9rem;
    font-family: "roboto-medium";
    color: var(--color-gray-black);
  }
  