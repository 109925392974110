@import '../../../stylesheets/rds-variable.css';

.lvFranchiseRibbonContainer {
  background-color: var(--neutral-050);
  position: relative;
  width: 100%;
  height: auto;
  max-height: 64px;
  padding: 2px 0;
}

.lvFranchiseRibbonContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 0;
  color: var(--shades-darken-10);
}

.group {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.group svg {
  margin-right: 10px;
}

.group a {
  text-decoration: none;
  color: var(--shades-darken-10);
  line-height: 15px;
  font-size: 0.85rem;
}

.phoneGroup {
  display: none;
}

.fontInherit {
  font-family: inherit;
  letter-spacing: 1px;
}

.displayStyles {
  font-size: .875rem;
}

.fw700 {
  font-weight: 700;
}

.fw400 {
  font-weight: 400;
}

.formatFranchise {
  color: var(--shades-darken-10);
  text-decoration: none;
  letter-spacing: unset;
  text-align: center;
}

@media screen and (min-width: 768px) {
  .lvFranchiseRibbonContent {
    padding: 5px 0;
  }

  .group {
    align-items: flex-start;
    width: auto;
    justify-content: initial !important;
  }

  .group svg {
    margin-right: 10px;
  }

  .group a {
    text-decoration: none;
    line-height: normal;
    font-size: 18px;
  }

  .phoneGroup {
    display: flex;
  }

  .displayStyles {
    font-size: 1.25rem;
  }

}