.overlayContent {
  color: #fff;
  background-color: rgba(18, 18, 18, 0.66);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  padding: 6rem;
}

.title .subTitle {
  color: var(--typography-inverse-primary, rgba(255, 255, 255, 0.96));
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
}
/* background: linear-gradient(90deg, rgba(18, 18, 18, 0.66) 50%, rgba(0,0,0,0) 50%); */
.subTitle {
  font-size: 16px;
}
.description {
  font-family: "roboto-light";
  font-size: 1rem;
  font-weight: 100;
}

@media screen and (min-width: 767px) {
  .overlayContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 23rem;
    width: 100%;
    padding-left: 7rem;
  }
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .overlayContent {
    height: max-content;
    width: 85vw;
    margin-left: 7.5vw;
  }
}

/* Font Size management */
@media screen and (min-width: 551px) {
  .title {
    font-size: 48px;
    line-height: 56px; /* 116.667% */
    letter-spacing: -0.5px;
  }

  .subTitle {
    font-size: 16px;
    line-height: 32px; /* 133.333% */
    letter-spacing: 0.18px;
  }
}

/* Font Size management for very small mobile */
@media screen and (min-width: 320px) and (max-width: 550px) {
  .title {
    font-size: 2.5rem;
  }

  .subTitle {
    margin-top: 5px;
    line-height: 1.5;
  }
}

.overlayContentMobile {
  color: #fff;
  background-color: rgba(18, 18, 18, 0.66);
  padding: 15px;
  margin: 10px;
}
