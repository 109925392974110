@import "../../../stylesheets/rds-common.css";
@import "../../../stylesheets/rds-variable.css";

.copyRightContainer {
  display: flex;
  padding: 16px 0px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 4px;
  background-color: var(--color-gray-black);
}

.navItems,
.privacyContainer {
  color: var(--shades-lighten-07, rgba(255, 255, 255, 0.67));
  text-align: center;
  font-size: 11px;
  font-weight: 400;
  line-height: 16px; /* 145.455% */
  letter-spacing: 0.4px;
  background-color: var(--color-gray-black);
}

.privacyContainer a {
  text-decoration-line: underline;
  color: var(--shades-lighten-07, rgba(255, 255, 255, 0.67));
}

.privacyContainer img {
  margin-left: 5px;
}

.privacyContainer:last-of-type span {
  display: none;
}
.vr, .separator{
  color: var(--shades-lighten-03, rgba(255, 255, 255, 0.26));;
}
@media screen and (min-width: 767px) {
  .copyRightContainer {
    font-size: 0.9rem;
    flex-direction: row;
  }
}

@media screen and (max-width: 767px) {
  .navItems .vr {
    display: none;
  }
}
