@import '../../../stylesheets/rds-variable.css';

.mobileSlidePanelContainer {
    background-color: rgba(0, 0, 0, .5);
    height: 100vh;
    position: fixed;
    width: 100%;
    top: 0;
    backdrop-filter: blur(8px);
    z-index: 1031;
}

.mobileSlideMenu {
    background-color: var(--color-white);
    height: 100vh;
    position: fixed;
    width: 90%;
    z-index: 1032;
    overflow: hidden;
    top: 0;
}

.menuContainer {
    background-color: var(--neutral-100);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
}

.topPanel {
    background-color: var(--color-white);
    border-bottom: 1px solid var(--color-hr-line);
}

.CTAPanel {
    background-color: var(--color-white);
    border-top: 1px solid var(--color-hr-line);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
    gap: 10px;
}

.multilevelMenuContainer {
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
}

.bgGray {
    background-color: var(--shades-darken-02);
}

@media screen and (min-width: 768px) {
    .mobileSlidePanelContainer, .mobileSlideMenu {
        display: none;
    }
}
