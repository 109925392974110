@import "./rds-variable.css";
@import "./rds-common.css";

.card {
  border: 1px solid rgba(18, 18, 18, 0.12) !important;
  border-radius: 8px;
  font-family: inherit;
  container: card / inline-size;
}

.title {
  margin: 0;
  cursor: pointer;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.footer {
  text-align: left;
  color: var(--shades-darken-10);
  font-size: 10px;
}

.category,
.more {
  color: var(--brand-primary-600);
  cursor: pointer;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 500;
}

.category:not(:last-child)::after {
  content: "|";
  color: var(--neutral-200, #cdcdcd);
  padding: 0 8px;
}

.extras,
.checkbox {
  display: none;
}

.checkbox:checked~.extras {
  display: inline-block;
}

.checkbox:checked~.more {
  display: none;
}

.image {
  cursor: pointer;
  height: auto;
  width: 100%;
  object-fit: cover;
  aspect-ratio: 9 / 6;
}

@container card (max-width: 350px) {
  .horizontal :global(.rds-font-product-title-1) {
    font-size: var(--font-size-subtitle-2);
    line-height: var(--font-line-height-subtitle-2);
    letter-spacing: var(--font-letter-spacing-subtitle-2);
  }

  .horizontal :global(.p-3) {
    /* Special case: changing a p-3 to a p-2 */
    padding: .5rem !important;
  }

  .horizontal :global(.col-3) {
    /* Special case: changing a col-3 to a col-5 */
    width: 41.66666667%;
  }

}