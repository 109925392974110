@import "./rds-variable.css";
@import "./rds-common.css";

:root {
  --font-feature-off: "clig" off, "liga" off;
  --text-align-center: center;
  --border-common: 3px dashed var(--color-gray-variant1);
  --border-radius: 8px;
  --max-width: 25rem;
  --overflow: hidden;
  --text-overflow: ellipsis;
}

.rds-rows {
  gap: 8px;
  margin-bottom: 24px;
}

.rds-rows > [class^="col-"] {
  margin-bottom: 0 !important;
  /* remove bootstrap default row spacing */
}

.rds-offers-container {
  margin-top: 1em;
  margin-bottom: 1em;
}

.rds-offer-heading,
.rds-offer-summary,
.rds-offer-termsAndCondition,
.rds-button-container {
  font-family: var(--font-regular);
  font-style: normal;
  font-feature-settings: var(--font-feature-off);
  text-align: var(--text-align-center);
  max-width: var(--max-width);
  text-overflow: var(--text-overflow);
}

.rds-offer-button-container button span {
  font-weight: 500;
  font-size: 10px;
}

.rds-offer-serviceLine,
.rds-offer-sectionHeading,
.rds-offer-heading {
  text-align: center;
}

.rds-offer-serviceLine,
.rds-offer-sectionHeading {
  color: var(--color-primary);
  text-transform: uppercase;
  display: flex;
  padding: 0px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  min-height: 34px;
}

.rds-offer {
  /* Center align the containers */
  background-color: #ffffff;
  border-radius: var(--border-radius);
  border: var(--border-common);
  margin: 0 0.5rem;
  padding: 1.5rem 1rem;
  width: 370px !important;
}

.rds-offer-expiration {
  color: var(--rds-color-text-quaternary);
}

.rds-offer-heading {
  color: var(--color-gray-black);
  font-size: 32px;
  font-weight: 500;
  min-height: 80px;
  line-height: 40px;
  max-width: 27rem;
}

.rds-offer-summary {
  height: 9rem;
  margin-bottom: 0;
}

.rds-offer-termsAndCondition {
  position: relative;
  text-align: left;
}

.truncated-description {
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.ellipsis {
  background-color: #ffffff;
  position: absolute;
  bottom: 10px;
  right: 0;
}

.full-description {
  display: none;
}

.see-more {
  color: var(--brand-primary-600);
  cursor: pointer;
  text-decoration: underline;
  text-transform: none;
}

.pop-up,
.icon {
  display: flex;
  flex-direction: column;
  opacity: 0;
  transition: opacity 0.3s, visibility 0.3s;
  visibility: hidden;
}

.pop-up {
  align-items: flex-start;
  background: var(--neutral-700);
  border-radius: var(--border-radius-base);
  padding: var(--units-4x);
  position: absolute;
  width: 100%;
  z-index: 1;
}

.icon {
  align-items: flex-end;
  align-self: stretch;
  padding: 0px var(--units-4x);
}

.pop-up-text {
  color: var(--Typography-Inverse-Primary);
}

.checkbox {
  display: none;
}

.checkbox:checked ~ .pop-up,
.checkbox:checked ~ .icon {
  opacity: 1;
  visibility: visible;
}

.print-offer-class {
  display: none;
}

@media screen and (min-width: 1400px) {
  .rds-offer-button-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    gap: 5px;
  }
}

@media screen and (min-width: 991px) and (max-width: 1399px) {
  .rds-offer-button-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    gap: 3px;
  }
}

@media screen and (min-width: 767px) and (max-width: 990px) {
  .rds-offer-button-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    gap: 3px;
    width: 17em;
  }
}

@media screen and (min-width: 451px) and (max-width: 766px) {
  .rds-offer-button-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    gap: 5px;
  }
}

@media screen and (max-width: 450px) {
  .rds-offer-button-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    gap: 3px;
  }

  @media screen and (max-width: 768px) {
    .ellipsis {
      bottom: 2px;
    }

    .pop-up,
    .icon {
      display: none;
    }

    .checkbox:checked ~ .ellipsis,
    .checkbox:checked ~ .truncated-description {
      display: none;
    }

    .checkbox:checked ~ .full-description {
      display: block;
    }
  }
}
