.rds-subtitle-1 {
  font-family: var(--font-regular);
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.15px;
}
.rds-subtitle-2 {
  font-family: var(--font-regular);
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
}
.rds-transition-default {
  transition: all 0.2s ease;
}
.rds-button-default {
  position: relative;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  border-radius: 4px;
  font-family: var(--font-regular);
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
}
.rds-button-text {
  position: relative;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  font-family: var(--font-regular);
  font-weight: 400;
}
.rds-outline {
  outline: none;
}
.rds-outline-focus {
  box-shadow: 0 0 0 2px #5e9ed6;
}
.rds-outline-focus-inverted {
  box-shadow: 0 0 0 2px #5e9ed6;
}
.rds-outline-focus-not-visible {
  outline: none;
}
.rds-btn-icon,
.rds-btn,
.rds-btn-text {
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
}
.rds-btn-icon:disabled,
.rds-btn:disabled,
.rds-btn-text:disabled {
  opacity: 0.25;
  cursor: not-allowed;
}
.rds-button-default {
  position: relative;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  border-radius: 4px;
  font-family: var(--font-regular);
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
}
.rds-input-checkbox input:disabled {
  opacity: 0.25;
  cursor: not-allowed;
}
.rds-select-menu input:disabled {
  opacity: 0.25;
  cursor: not-allowed;
}
a.rds-text-link,
button.rds-text-link {
  background: none;
  border: none;
  position: relative;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  font-family: var(--font-regular);
  outline: none;
  transition: all 0.2s ease;
  font-weight: 400;
  text-transform: none;
  letter-spacing: 0;
  padding: 0px;
}
a.rds-text-link.large,
button.rds-text-link.large {
  font-size: 16px;
  line-height: 16px;
}
a.rds-text-link.large .fa-icon,
button.rds-text-link.large .fa-icon {
  font-size: 16px;
  line-height: 0.8125;
  position: relative;
}
a.rds-text-link.large .fa-icon.leading,
button.rds-text-link.large .fa-icon.leading {
  margin-right: 8px;
}
a.rds-text-link.large .fa-icon.trailing,
button.rds-text-link.large .fa-icon.trailing {
  margin-left: 8px;
}
a.rds-text-link.small,
button.rds-text-link.small {
  font-size: 14px;
  line-height: 16px;
}
a.rds-text-link.small .fa-icon,
button.rds-text-link.small .fa-icon {
  font-size: 14px;
  line-height: 16px;
  position: relative;
}
a.rds-text-link.small .fa-icon.leading,
button.rds-text-link.small .fa-icon.leading {
  margin-right: 4px;
}
a.rds-text-link.small .fa-icon.trailing,
button.rds-text-link.small .fa-icon.trailing {
  margin-left: 4px;
}
a.rds-text-link::before,
button.rds-text-link::before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 1px;
  bottom: -2px;
  left: 0;
  color: inherit;
  transition: all 0.2s ease;
  padding: 0px;
  top: auto;
}
a.rds-text-link:hover::before,
button.rds-text-link:hover::before {
  transform: scaleY(2);
  transform-origin: center top;
  transition: all 0.2s ease;
}
a.rds-text-link:focused,
button.rds-text-link:focused {
  box-shadow: 0 0 0 2px #5e9ed6;
}
a.rds-text-link:focus:not(:focus-visible),
button.rds-text-link:focus:not(:focus-visible) {
  outline: none;
}
a.rds-text-link:focus-visible,
button.rds-text-link:focus-visible {
  box-shadow: 0 0 0 2px #5e9ed6;
}
a.rds-text-link:focus,
button.rds-text-link:focus {
  box-shadow: 0 0 0 2px #5e9ed6;
}
a.rds-text-link:disabled:hover::before,
button.rds-text-link:disabled:hover::before {
  transform: scaleY(1);
}
a.rds-text-link.primary,
button.rds-text-link.primary {
  color: #d40029;
}
a.rds-text-link.primary::before,
button.rds-text-link.primary::before {
  background-color: #d40029;
}
a.rds-text-link.primary:hover,
button.rds-text-link.primary:hover {
  color: #f01839;
}
a.rds-text-link.primary:hover::before,
button.rds-text-link.primary:hover::before {
  background-color: #f01839;
}
a.rds-text-link.primary:active,
button.rds-text-link.primary:active {
  color: #aa001e;
}
a.rds-text-link.primary:active::before,
button.rds-text-link.primary:active::before {
  background-color: #aa001e;
}
a.rds-text-link.primary:disabled:hover,
button.rds-text-link.primary:disabled:hover {
  color: #d40029;
}
a.rds-text-link.primary:disabled:hover::before,
button.rds-text-link.primary:disabled:hover::before {
  background-color: #d40029;
  transform: scaleY(1);
}
a.rds-text-link.secondary,
button.rds-text-link.secondary {
  color: #333333;
}
a.rds-text-link.secondary::before,
button.rds-text-link.secondary::before {
  background-color: #333333;
}
a.rds-text-link.secondary:hover,
button.rds-text-link.secondary:hover {
  color: #515151;
}
a.rds-text-link.secondary:hover::before,
button.rds-text-link.secondary:hover::before {
  background-color: #515151;
}
a.rds-text-link.secondary:active,
button.rds-text-link.secondary:active {
  color: #222222;
}
a.rds-text-link.secondary:active::before,
button.rds-text-link.secondary:active::before {
  background-color: #222222;
}
a.rds-text-link.secondary:disabled:hover,
button.rds-text-link.secondary:disabled:hover {
  color: #333333;
}
a.rds-text-link.secondary:disabled:hover::before,
button.rds-text-link.secondary:disabled:hover::before {
  background-color: #333333;
}
a.rds-text-link.inverted,
button.rds-text-link.inverted {
  color: #ffffff;
}
a.rds-text-link.inverted::before,
button.rds-text-link.inverted::before {
  background-color: #ffffff;
}
a.rds-text-link.inverted:hover,
button.rds-text-link.inverted:hover {
  color: #ffffff;
}
a.rds-text-link.inverted:hover::before,
button.rds-text-link.inverted:hover::before {
  background-color: #ffffff;
}
a.rds-text-link.inverted:focused,
button.rds-text-link.inverted:focused {
  box-shadow: 0 0 0 2px #5e9ed6;
}
a.rds-text-link.inverted:focus:not(:focus-visible),
button.rds-text-link.inverted:focus:not(:focus-visible) {
  outline: none;
}
a.rds-text-link.inverted:focus-visible,
button.rds-text-link.inverted:focus-visible {
  box-shadow: 0 0 0 2px #5e9ed6;
}
a.rds-text-link.inverted:active,
button.rds-text-link.inverted:active {
  color: #ffffff;
}
a.rds-text-link.inverted:active::before,
button.rds-text-link.inverted:active::before {
  background-color: #ffffff;
}
a.rds-text-link.inverted:disabled:hover,
button.rds-text-link.inverted:disabled:hover {
  color: #ffffff;
}
a.rds-text-link.inverted:disabled:hover::before,
button.rds-text-link.inverted:disabled:hover::before {
  background-color: #ffffff;
}
