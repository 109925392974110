.logoPanel {
    padding: .5rem 1rem;
}

.backButton {
    opacity: 0;
    transition: all 300ms ease-in;
}

.image {
    width: 100px;
    object-fit: contain;
    height: auto;
}

@media screen and (min-width: 768px) {
    .image {
        display: none;
    }
}
