@import "./rds-variable.css";

.rds-subtitle-1 {
  font-family: var(--font-regular);
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.15px;
}

.rds-subtitle-2 {
  font-family: var(--font-regular);
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
}

.rds-subtitle-2.date-format-label {
  padding-left: 16px;
  color: var(--shades-darken-08);
}

.rds-transition-default {
  transition: all 0.2s ease;
}

.rds-button-default {
  position: relative;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  border-radius: 4px;
  font-family: var(--font-regular);
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
}

.rds-button-text {
  position: relative;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  font-family: var(--font-regular);
  font-weight: 400;
}

.rds-outline {
  outline: none;
}

.rds-outline-focus {
  box-shadow: 0 0 0 2px #5e9ed6;
}

.rds-outline-focus-inverted {
  box-shadow: 0 0 0 2px #5e9ed6;
}

.rds-outline-focus-not-visible {
  outline: none;
}

.rds-btn-icon,
.rds-btn,
.rds-btn-text {
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
}

.rds-btn-icon:disabled,
.rds-btn:disabled,
.rds-btn-text:disabled {
  opacity: 0.25;
  cursor: not-allowed;
}

.rds-button-default {
  position: relative;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  border-radius: 4px;
  font-family: var(--font-regular);
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
}

.rds-input-checkbox input:disabled {
  opacity: 0.25;
  cursor: not-allowed;
}

.rds-select-menu input:disabled {
  opacity: 0.25;
  cursor: not-allowed;
}

.rds-input-text {
  position: relative;
  font-family: var(--font-regular);
}

.rds-input-text .input-autofilled {
  top: -0.781rem;
}

.rds-input-text:focus-within button {
  display: block;
}

.rds-input-text input,
.rds-input-text select {
  width: 100%;
  height: 48px;
  position: relative;
  box-sizing: border-box;
  color: var(--shades-darken-10);
  box-shadow: 0 0 0 1px #333333 inset;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.1px;
  border: none;
  outline: none;
  padding: 16px;
  border-radius: 4px;
  background: var(--color-white) !important;
}

.rds-input-text input {
  -webkit-appearance: none;
}

.rds-input-text input:placeholder-shown {
  color: #626262;
  box-shadow: 0 0 0 1px #7e7e7e inset;
}

.rds-input-text input:focus,
.rds-input-text input:hover {
  box-shadow: 0 0 0 2px #333333 inset;
  color: #222222;
  transition: all 0.2s ease;
}

.rds-input-text input::placeholder {
  color: rgba(0, 0, 0, 0);
}

.rds-input-text input:disabled:hover {
  box-shadow: 0 0 0 1px #7e7e7e inset;
}

.rds-input-text input:disabled:hover+label::before {
  color: #d9d9d9;
  opacity: 1;
}

.rds-input-text input:disabled:focus+.label-wrap label::before {
  color: #d9d9d9;
  opacity: 1;
}

.rds-input-text input:disabled+.label-wrap label::before {
  color: #d9d9d9;
  opacity: 1;
}

.rds-input-text .label-wrap {
  position: absolute;
  left: 12px;
}

.rds-input-text .label-wrap label {
  display: block;
  position: relative;
  max-height: 0;
  pointer-events: none;
}

.rds-input-text .label-wrap label::before {
  content: attr(data-content);
  filter: blur(0);
  color: #626262;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  letter-spacing: 0.1px;
  padding: 0 4px;
  background-image: url("../assets/input-label-background.svg");
  background-repeat: repeat-x;
  background-size: 8px;
  background-position: center 8px;
  transition: all 0.2s ease;
}

.rds-input-text button {
  display: none;
  width: 40px;
  height: 48px;
  text-align: center;
  position: absolute;
  right: 16px;
  top: 0;
  color: var(--shades-darken-10);
  background-color: unset;
  border: unset;
}

.rds-input-text button .fa-icon {
  font-size: 16px;
  line-height: 16px;
  color: var(--shades-darken-10);
  padding: 16px 16px 16px 8px;
  width: 16px;
  height: 16px;
}

.rds-input-text button .fa-icon:hover {
  cursor: pointer;
}

.rds-input-text input:placeholder-shown+.label-wrap {
  top: 0.781rem;
}

.rds-input-text .label-wrap,
.rds-input-text input:focus+.label-wrap {
  top: -0.781rem;
  transition: all 0.2s ease;
  border-radius: 2px;
}

.rds-input-text input:placeholder-shown+.label-wrap label::before {
  color: var(--shades-darken-07);
  font-size: 14px;
  line-height: 16px;
  background: rgba(255, 255, 255, 0.96);
}

.rds-input-text input:placeholder-shown:disabled+.label-wrap label::before {
  color: #d9d9d9;
}

.rds-input-text .label-wrap label::before,
.rds-input-text input:focus+.label-wrap label::before {
  font-size: 12px;
  line-height: none;
  color: var(--shades-darken-10);
  letter-spacing: 0.4px;
  background-color: var(--color-white);
  border-radius: 2px;
}

.rds-input-text .hidden-label {
  border: 0;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.rds-input-text.error input {
  box-shadow: 0 0 0 1px var(--brand-primary-300) inset;
}

.rds-input-text.error input:focus,
.rds-input-text.error input:hover {
  box-shadow: 0 0 0 2px var(--brand-primary-300) inset;
}

.rds-input-text.error input+.label-wrap label::before,
.rds-input-text.error input:placeholder-shown+.label-wrap label::before,
.rds-input-text.error input:focus+.label-wrap label::before,
.rds-input-text.error input:hover+.label-wrap label::before {
  color: var(--brand-primary-300);
}

.rds-input-text .rds-input-helper-text{
  font-family: var(--font-regular);
  margin: 4px 16px 0 0;
  color: var(--shades-darken-07);
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
}

.rds-input-text .rds-input-helper-text:empty,
.rds-input-text .rds-input-validation-text:empty {
  display: none !important;
}

.rds-input-text .rds-input-helper-text.error-text,
.rds-input-text .rds-input-validation-text.error-text {
  color: var(--brand-primary-300);
}