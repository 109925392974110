@import "./rds-variable.css";
@import "./rds-common.css";

.franchiseHomeTileCardTrayWrapper {
  padding-top: 32px;
  border-top: 1px solid var(--neutral-100, #e1e1e1);
  border-bottom: 1px solid var(--neutral-100, #e1e1e1);
  background-image: url("../assets/GrayTraingles-On-WhiteBg.png");
}

.heading,
.subHeading,
.license,
.franchiseRating,
.reviewLink {
  color: var(--rds-color-text-primary);
  font-feature-settings: "liga" off, "clig" off;
  font-family: inherit;
  font-style: normal;
}

.heading {
  /* ~rds-font-title-3 */
  font-size: 40px;
  font-weight: 800;
  line-height: 48px; /* 120% */
}

.subHeading {
  /* ~rds-font-title-4 */
  font-size: 32px;
  font-weight: 800;
  line-height: 40px; /* 125% */
}

.license {
  font-size: 20px;
  line-height: 24px; /* 120% */
  letter-spacing: 0.15px;
}

.franchiseRating {
  /* rds-font-product-title-1 */
  font-size: 20px;
  font-weight: 500;
  line-height: 24px; /* 120% */
  letter-spacing: 0.15px;
}

.ratingStar {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}

.reviewLink {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.25px;
  text-decoration-line: underline;
}

.bookServiceText {
  color: var(--neutral-800, #333);
  font-feature-settings: "liga" off, "clig" off;

  /* rds-font-caption */
  font-family: inherit;
  font-size: var(--font-size-caption, 12px);
  font-style: normal;
  font-weight: var(--font-weight-regular, 400);
  line-height: var(--font-line-height-caption, 16px); /* 133.333% */
  letter-spacing: 0.4px;
}

.phoneNumber {
  color: var(--brand-primary-600, #d40029);
  font-feature-settings: "liga" off, "clig" off;
  font-family: inherit;
  font-size: var(--font-size-title-5, 24px);
  font-style: normal;
  font-weight: var(--font-weight-medium, 500);
  line-height: var(--font-line-height-product-title-1, 24px); /* 100% */
  letter-spacing: 0.15px;

  text-decoration: none;
}

.phoneIcon {
  width: 24px;
  height: 24px;
}

@media only screen and (max-width: 768px) {
  .heading {
    font-size: 32px;
    font-weight: 800;
    line-height: 40px; /* 125% */
  }

  .subHeading {
    font-size: 24px;
    font-weight: 800;
    line-height: 32px; /* 133.333% */
    letter-spacing: 0.18px;
  }

  .license {
    font-size: 16px;
    line-height: 22px; /* 137.5% */
    letter-spacing: 0.15px;
  }
}
