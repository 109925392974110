.mainMenuContainer {
    width: 100%;
    position: relative;
    background-color: var(--color-white);
  }
  
  .menuList {
    list-style: none;
    padding: 1rem 1rem 1rem 1.5rem;
    margin-bottom: 0;
  }
