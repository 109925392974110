.bcContainer {
    list-style: none;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow: hidden;
    margin: 0;
    padding: 1rem 0;
    font-size: 0.9rem;
}

.bcContainer li {
    white-space: nowrap;
}

.bcContainer a {
    color: var(--color-gray-variant1);
    text-decoration: underline;
}

.bcItemSeparator {
    display: inline-block;
    padding: 0 10px;
    color: var(--color-gray-variant1);
}

.bcBlockSeparator {
    border-top: 1px solid var(--color-hr-line);
}

.bcMarginTopMob {
    margin-top: 6rem;
}

.bcMarginTopMobile{
    margin-top: 4rem;
}

.bcMarginTop {
    margin-top: 7rem;
}

.bcMarginTopTab {
    margin-top: 5.3rem;
}

.bcMarginTopForSecondaryNav{
    margin-top: 3rem;
}