@import "./rds-variable.css";
@import "./rds-common.css";

.image {
  display: block;
  width: 100%;
  height: auto;
  object-fit: cover;
}
.textContent {
  text-align: center;
  sup {
    font-size: 0.5em;
    vertical-align: super;
  }
}

.anchorText a {
  color: var(--brand-primary-600);
}

@media only screen and (min-width: 768px) {
  .mainWrapper {
    min-height: 300px;
    max-height: 700px;
    overflow: hidden;
  }
  .textContent {
    text-align: left;
    sup {
      font-size: 0.5em;
      vertical-align: super;
    }
  }
  :global(h2.rds-section-heading) {
    text-align: left;
  }
}
