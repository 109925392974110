.errorContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-self: center;
    padding: 12px 16px;

}

.headerTextStyle {
    padding-top: 32px;
    font-family: "Roboto";
    font-size: 60px;
    font-weight: 700;
    line-height: 72px;
    letter-spacing: 0.5px;
    text-align: center;
}

.detailTextStyle {
    margin-top: 30px;
    font-family: "Roboto";
    padding-left: 20%;
    padding-right: 20%;
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0.18px;
    text-align: center;
}

.header2TextStyle {
    margin-top: 30px;
    font-family: "Roboto";
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0.18px;
    text-align: center;
    padding-bottom: 32px;
}

.footerTextStyle {
    padding-top: 32px;
    font-family: "Roboto";
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0.18px;
    text-align: center;

}

.image {
    height: 162px;
    width: 352px;
}