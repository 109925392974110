@import "./rds-variable.css";
@import "./rds-common.css";

.cityName {
  color: var(--Typography-Default-Secondary, rgba(18, 18, 18, 0.86));
  font-feature-settings: "liga" off, "clig" off;
}

.visualMap {
  width: 100%;
  min-height: 327px;
  max-height: 380px;
  min-width: 291px;
  max-width: 600px;
  position: relative;
  overflow: hidden;
}

.visualMapPlaceHolder {
  max-height: 380px;
  min-width: 250px;
  max-width: 600px;
}

.sectionHeading {
  color: var(--brand-primary-600, #d40029);
  text-align: center;
  font-feature-settings: "liga" off, "clig" off;

  font-family: var(--font-family-Roboto, Roboto);
  font-size: var(--font-size-button, 14px);
  font-style: normal;
  font-weight: var(--font-weight-medium, 500);
  line-height: var(--font-line-height-button, 16px); /* 114.286% */
  letter-spacing: 1.25px;
  text-transform: uppercase;
}

.heading {
  color: var(
    --rds-color-text-primary,
    var(--shades-darken-10, rgba(18, 18, 18, 0.93))
  );
  text-align: center;
  font-feature-settings: "liga" off, "clig" off;
  font-family: "Roboto Slab";
  font-size: var(--font-size-title-4, 32px);
  font-style: normal;
  font-weight: 700;
  line-height: var(--font-line-height-title-4, 40px); /* 125% */
}

:global(.rds-font-body-1) {
  font-family: var(--font-family-Roboto, Roboto);
  font-size: var(--font-size-body-1, 16px);
  font-style: normal;
  font-weight: var(--font-weight-regular, 400);
  line-height: var(--font-line-height-body-1, 24px); /* 150% */
  letter-spacing: 0.5px;
}
