:root {
  --color-gray-100: #e4e4e4;
  --color-gray-black: #121212;
  --color-gray-variant1: #626262;
  --color-gray: #333333;
  --color-hr-line: #e3e3e3;
  --color-light-gray: #cacaca;
  --color-link-blue: #0d6efd;
  --color-primary: #d5202e;
  --color-secondary: #fff;
  --color-white: #fff;
  --neutral-100: #e1e1e1;
  --neutral-050: #f7f7f7;
  --neutral-700: #515151;
  --neutral-800: #333333;
  --brand-primary-600: #d40029;
  --brand-primary-300: #ff7878;
  --shades-darken-06: rgba(18, 18, 18, 0.55);
  --shades-darken-07: rgba(18, 18, 18, 0.66);
  --shades-darken-08: rgba(18, 18, 18, 0.74);
  --shades-darken-09: rgba(18, 18, 18, 0.86);
  --shades-darken-10: rgba(18, 18, 18, 0.93);
  --shades-lighten-10: rgba(255, 255, 255, 0.96);
  --rds-color-text-primary: var(--shades-darken-10, rgba(18, 18, 18, 0.93));
  --rds-color-text-secondary: var(--shades-darken-09, rgba(18, 18, 18, 0.86));
  --Typography-Inverse-Primary: var(--shades-lighten-10,
      rgba(255, 255, 255, 0.96));
  --rds-color-text-tertiary: var(--shades-darken-08, rgba(18, 18, 18, 0.74));
  --rds-color-text-quaternary: var(--shades-darken-07, rgba(18, 18, 18, 0.66));

  /* Units */
  --units-4x: 16px;

  /* Border */
  --border-radius-base: 4px;

  /* Font */
  --font-style: "normal";
  --font-feature-off: "clig" off, "liga" off;
  --font-family-Roboto: "Roboto", "Arial" -apple-system, sans-serif;
  --font-family-regular: "Roboto Regular", "Arial" -apple-system, sans-serif;
  --font-family-slab: "Roboto Slab", "Arial" -apple-system, sans-serif;
  /* TODO: replace --font-regular occurrences with --font-family-regular */
  --font-regular: "roboto-regular", "Arial" -apple-system, sans-serif;
  --font-medium: "roboto-medium", "Arial" -apple-system, sans-serif;
  /* TODO: replace --font-medium occurrences with --font-family-medium */
  --font-family-medium: "Roboto Medium", "Arial" -apple-system, sans-serif;
  --font-bold: "roboto-bold", "Arial" -apple-system, sans-serif;
  /* TODO: replace --font-bold occurrences with --font-family-bold */
  --font-family-bold: "roboto-bold", "Arial" -apple-system, sans-serif;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  /* Created the below variable, not in Figma */
  --font-weight-bold: 700;
  --font-weight-extra-bold: 800;

  /* rds-font-title-1 */
  --font-size-title-1: 60px;
  --font-line-height-title-1: 72px;
  --font-letter-spacing-title-1: 0.5px;

  /* rds-font-title-2 */
  --font-size-title-2: 48px;
  --font-line-height-title-2: 56px;
  --font-letter-spacing-title-2: -0.5px;

  /* rds-font-title-3 */
  --font-size-title-3: 40px;
  --font-line-height-title-3: 48px;
  --font-letter-spacing-title-3: 0px;

  /* rds-font-title-4 */
  --font-size-title-4: 32px;
  --font-line-height-title-4: 40px;
  --font-letter-spacing-title-4: 0px;

  /* rds-font-title-5 */
  --font-size-title-5: 24px;
  --font-line-height-title-5: 32px;
  --font-letter-spacing-title-5: 0.18000000715255737px;

  /* rds-font-product-title-1 */
  --font-size-product-title-1: 20px;
  --font-line-height-product-title-1: 24px;
  --font-letter-spacing-product-title-1: 0.15000000596046448px;

  /* rds-font-product-title-2 */
  --font-size-product-title-2: 16px;
  --font-line-height-product-title-2: 20px;
  --font-letter-spacing-product-title-2: 0.15000000596046448px;

  /* ~rds-font-product-title-4 */
  --font-size-product-title-4: 16px;
  --font-line-height-product-title-4: 16px;
  --font-letter-spacing-product-title-4: 0.15px;

  /* rds-font-subtitle-1 */
  --font-size-subtitle-1: 16px;
  --font-line-height-subtitle-1: 16px;
  --font-letter-spacing-subtitle-1: 0.15px;

  /* rds-font-subtitle-2 */
  --font-size-subtitle-2: 14px;
  --font-line-height-subtitle-2: 16px;
  --font-letter-spacing-subtitle-2: 0.1px;

  /* rds-font-body-1 */
  --font-size-body-1: 16px;
  --font-line-height-body-1: 24px;
  --font-letter-spacing-body-1: 0.5px;

  /* rds-font-body-2 */
  --font-size-body-1: 14px;
  --font-line-height-body-1: 20px;
  --font-letter-spacing-body-1: 0.25px;

  /* rds-font-button */
  --font-size-button: 14px;
  --font-line-height-button: 20px;
  --font-letter-spacing-button: 0.25px;

  /* rds-font-caption */
  --font-size-button: 12px;
  --font-line-height-button: 16px;
  --font-letter-spacing-button: 0.4000000059604645px;

  /* ~rds-font-label */
  --font-size-label: 14px;
  --font-line-height-label: 16px;
  --font-letter-spacing-label: 2px;

  /* ~rds-font-label-2 */
  --font-size-label-2: 10px;
  --font-line-height-label-2: 16px;
  --font-letter-spacing-label-2: 1px;

  /* Units */
  --default-rem-unit: 1rem;
}

@media (max-width: 768px) {
  :root {
    /* rds-font-title-1 goes to rds-font-title-3 treatment for mobile*/
    --font-size-title-1: 40px;
    --font-line-height-title-1: 48px;
    --font-letter-spacing-title-1: 0px;

    /* rds-font-title-2 goes to rds-font-title-4 treatment for mobile*/
    --font-size-title-2: 32px;
    --font-line-height-title-2: 40px;
    --font-letter-spacing-title-2: 0px;

    /* rds-font-title-3  will go to rds-font-title-4 for mobile*/
    --font-size-title-3: 32px;
    --font-line-height-title-3: 40px;

    /* rds-font-title-5 goes to rds-font-product-title-1 treatment for mobile*/
    --font-size-title-5: 20px;
    --font-line-height-title-5: 24px;
    --font-letter-spacing-title-5: 0.15px;

    /* rds-font-label goes to rds-font-label (stays) treatment for mobile*/

    /* rds-font-subtitle-1 goes to rds-font-subtitle-1 (stays) treatment for mobile*/

    /* rds-font-product-title-1 goes to rds-font-subtitle-2 treatment for mobile*/
    --font-size-product-title-1: 14px;
    --font-line-height-product-title-1: 16px;
    --font-letter-spacing-product-title-1: 0.10000000149011612px;

    /* ~rds-font-product-title-4 goes to ~rds-font-product-title-4 (stays) treatment for mobile*/
  }
}
