.heading {
  color: var(--color-primary);
  text-transform: uppercase;
  font-size: 10px;
  font-style: normal;
  font-weight: bold;
  line-height: 16px;
  letter-spacing: 1px;
}

.subHeading {
  color: var(--color-gray-black);
  font-family: "Roboto Slab";
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 48px;
}

.bgImage {
  background-image: url("../../../assets/common/bg-pattern-triangles.png");
}

@media screen and (min-width: 768px) {
  .heading {
    font-size: 0.8rem;
    text-align: left;
  }

  .subHeading {
    font-size: 2.5rem;
    text-align: left;
  }
}
