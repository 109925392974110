.heroBanner {
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background-position:90%;
  background-size: cover;
}

.heroBannerHeight {
  min-height: 15rem;
}

@media screen and (min-width: 768px) {
  .heroBanner{
    background-position:center;
  }

  .heroBannerHeight {
    height: 23rem;
  }
}
