.containerBackground {
  background: rgb(247, 247, 247);
}
.cardWrapper {
  display: grid;
  grid-auto-rows: 1fr;
}
.linkStyle {
  font-size: 1rem;
  color: #d40029;
  font-weight: 400;
  text-decoration: underline;
  cursor: pointer;
}
.sectionTitle {
  font-weight: 600;
  font-size: 1.5rem;
}
@media (max-width: 576px) {
  .sectionTitle {
    font-size: 1.2rem;
  }
  .linkStyle {
    font-size: 0.9rem;
    color: #d40029;
    text-decoration: underline;
    cursor: pointer;
  }
}
