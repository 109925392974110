
  @import './rds-variable.css';
  @import './rds-common.css'; 

  .rds-contact-form-subHeading {
    color: var(--color-gray-black);
    font-size: 1rem;
    font-style: normal;
    line-height: 8px;
    text-align: center;
  }
  
  .rds-contact-form-button{
    display: flex;
    justify-content: flex-end;
    margin-right: 1rem;
  }