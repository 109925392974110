.rds-accordion.open .rds-accordion-title {
  background: #f7f7f7;
}
.rds-accordion.open .rds-accordion-title:hover {
  background: #e1e1e1;
}
@media only screen and (max-width: 991px) {
  .rds-accordion.open .rds-accordion-title.sticky {
    position: sticky;
    top: 0px;
  }
}
.rds-accordion.closed .rds-accordion-title:hover {
  background: #f7f7f7;
}
.rds-accordion.closed .rds-accordion-body input {
  display: none;
}
.rds-accordion.closed .rds-accordion-body button {
  display: none;
}
.rds-accordion .rds-accordion-title {
  cursor: pointer;
  padding: 16px;
  border-bottom: 1px solid #cdcdcd;
  display: flex;
}
.rds-accordion .rds-accordion-title:focus-visible {
  outline: 1px solid #027aff !important;
}
.rds-accordion .rds-accordion-title .rds-accordion-title-text {
  width: calc(100% - 64px);
  font-family: var(--font-regular);
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: rgba(18, 18, 18, 0.93);
}
.rds-accordion .rds-accordion-title i {
  margin: auto 8px auto auto;
  height: 24px !important;
  font-size: 24px;
}
.rds-accordion .rds-accordion-body {
  -webkit-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  overflow: hidden;
}
.rds-accordion .rds-accordion-body.no-height {
  height: 0px !important;
}
.rds-accordion .rds-accordion-body .rds-accordion-inner-body {
  padding: 16px;
}
.rds-accordion .rds-accordion-body .rds-accordion-inner-body.no-padding {
  padding: 16px 0 16px 0 !important;
}
