.label{
    font-size: 0.625rem;
    color: var(--color-gray);
}

.phoneGroup {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    gap: 10px;
}

.displayStyles {
    font-family: inherit;
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--color-primary);
}