@import "../../../stylesheets/rds-common.css";
@import "../../../stylesheets/rds-variable.css";

.addressContainer {
  display: flex;
  flex-direction: column;
  width: fit-content;
  background-color: var(--color-gray-black);
}

.addressContainer ul {
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 0;
  color: var(--color-light-gray);
}

.addressContainer li:last-of-type {
  margin-right: 0;
}

.addressContainer .heading {
  color: var(--shades-lighten-07, rgba(255, 255, 255, 0.67));
  font-size: 12px;
  font-weight: 400;
  line-height: 24px; /* 200% */
  letter-spacing: 0.5px;
}

.seperator {
  display: inline-block;
  height: 100%;
  width: 1px;
  background: var(--neutral-600, #626262);
  margin-right: 8px;
  margin-left: 8px;
}

.addressText {
  font-size: var(--font-size-caption, 12px);
  font-weight: var(--font-weight-regular, 400);
  line-height: var(--font-line-height-caption, 16px); /* 133.333% */
  letter-spacing: 0.4px;
  color: var(--shades-lighten-10, rgba(255, 255, 255, 0.96));
}

@media (max-width: 768px) {
  .addressContainer ul {
    flex-wrap: wrap;
  }
  .addressText {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
  }
  .seperator {
    margin-bottom: 16px;
  }
}
