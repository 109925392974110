.serviceListContainer {
  display: block;
}

.displayStyle {
  display: block;
}

.gap {
  gap: 0;
}

.justifyOnMob {
  justify-content: flex-start !important;
}

@media only screen and (min-width: 768px) {
  .serviceListContainer {
    display: flex;
    justify-content: center;
  }

  .displayStyle {
    display: flex;
  }

}

@media only screen and (min-width: 992px) {
  .gap {
    gap: 2rem;
  }
}

/*bootstrap does not prove xs device classes, hence adding */
@media only screen and (min-width: 300px) and (max-width: 576px) {
  .colxs6 {
    flex: 0 0 auto;
    width: 50% !important;
    display: grid;
  }
}
