@import '../../../stylesheets//rds-variable.css';

.secondayNavBarContainer {
    position: relative;
    width: 100%;
    background-color: var(--neutral-800);
    z-index: 1001;
    display: none;
    transition: all 1s ease-in-out;
  }
  
.fsXs {
  font-size: .875rem;
  color: var(--color-light-gray);
}

  @media screen and (min-width: 768px) {
    .secondayNavBarContainer {
      display: block;
    }
  }
  