.heading {
  color: var(--color-primary);
  font-size: 0.8rem;
  text-transform: uppercase;
  font-weight: bold;
  white-space: nowrap;
}

.subHeading {
  color: var(--color-gray-black);
  font-family: "roboto-bold";
  font-size: 2.5rem;
}
.bgImage {
  background-image: url('../../assets/common/bg-pattern-triangles.png');
}
