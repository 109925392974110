.linkWithArrow {
  display: inline-flex !important;
  cursor: pointer;
  text-decoration: none;
  align-items: center;
  font-family: "roboto-regular";
}

.linkWithArrow svg {
  margin-left: 10px;
}

.nonLink {
  font-family: inherit;
  font-size: 0.8rem;
  color: var(--color-primary);
  border-bottom: 1px solid;
}
