.form_container,
.form_container_mobile {
  background-color: white;
}

.form_container {
  margin-top: -10%;
  margin-left: 3rem;
  margin-right: 3rem;
  padding: 20px 50px;
}

.form_container_mobile {
  margin: 0.25rem;
  padding: 0 2px;
}
