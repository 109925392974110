.footerContainer {
  background-color: var(--color-gray-black);
  color: var(--color-white);
  position: relative;
}

.footerContainer a {
  color: var(--color-light-gray);
  text-decoration: none;
}
