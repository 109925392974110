@import url("./rds-variable.css");

.customBtn > .active {
  background-color: var(--neutral-100);
}

.customBtn > button {
  text-decoration: underline;
  color: var(--brand-primary-600);
  background-color: var(--color-white);
  width: var(--font-size-title-4);
  height: var(--font-line-height-title-5);
  border: 0.25px solid var(--neutral-100, #e1e1e1);
  text-underline-offset: 2px;
}
@media only screen and (max-width: 768px) {
  .customBtn > button {
    height: 1.75rem;
  }
}
