@import "./rds-variable.css";

.rds-review-carousel-heading {
  color: var(--color-primary);
  font-size: 0.8rem;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
}

.rds-review-carousel-subHeading {
  text-align: center;
  color: var(--color-gray-black);
  font-family: var(--font-bold);
  font-size: 2.5rem;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 0.5rem;
  margin-top: 0;
}

.rds-review-carousel-background {
  background: #f7f7f7;
}

.review-container {
  background: var(--color-white);
  padding: 2%;
  min-height: 200px;
}

.review-count {
  display: flex;
  justify-content: space-between;
}

.rds-rating-icon-reviews {
  width: 24px;
  height: 24px;
}

.review-count-label {
  color: var(--color-gray-black);
  font-size: 24px;
  font-family: var(--font-medium);
  line-height: 32px;
  padding-right: 5px;
  margin-top: -4px;
  letter-spacing: 0.18px;
}

.d-flex.rating span {
  margin-bottom: -16px;
}

.d-flex.rating span span {
  padding-right: 5px;
}

.review-title {
  color: var(--color-gray-black);
  font-size: 24px;
  font-family: var(--font-medium);
  line-height: 32px;
  text-align: left;
  letter-spacing: 0.18px;
}

.review-message {
  color: var(--color-gray-black);
  font-size: 16px;
  font-family: var(--font-regular);
  line-height: 24px;
  text-align: left;
  height: 120px;
  overflow: hidden;
  letter-spacing: 0.5px;
}

.rds-review-see-all {
  background: var(--brand-primary-600, #D40029);
  padding: 16px 24px;
  color: var(--color-white, #FFF);
  text-decoration: none;
  border-radius: 4px;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1.25px;
  font-family: var(--font-medium);
}

.review-img {
  width: 10% !important;
}

.rds-review-carousel-background .slick-slide {
  padding: 15px;
}

.rds-review-carousel-background .slick-prev::before,
.rds-review-carousel-background .slick-next::before {
  font-size: 40px;
  color: black;
}

.review-container.slider {
  padding: 24px;
}

.rds-review-carousel-background .slick-dots li {
  margin: 0 3px;
}

.rds-review-carousel-background .slick-dots li button::before {
  font-size: 9px;
}

.rds-review-carousel-background .mb-5 {
  margin-bottom: 0 !important;
}

.rds-review-carousel-background .py-4 {
  padding-top: 3.5rem !important;
}

.rds-review-carousel-background.mobile .slick-prev,
.rds-review-carousel-background.mobile .slick-next {
  display: none !important;
}
