@import "./rds-variable.css";

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.title {
  /* TODO: Apply font class instead of many of the styles below, .rds-font-label */
  font-size: var(--font-size-label);
  line-height: var(--font-line-height-label);
  letter-spacing: 1px;
  text-transform: uppercase;
  color: var(--shades-darken-08);
  font-family: var(--font-family-medium);
  text-align: left;

}

.yearsList{
  color: var(--brand-primary-600);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.year {
  /* TODO: Apply font class instead of many of the styles below, .rds-font-product-title-2 */
  display: flex;
  font-feature-settings: var(--font-feature-off);
  font-family: var(--font-family-medium);
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
  letter-spacing: 0.15px;
  text-decoration-line: underline;
  text-transform: uppercase;
}


.iconArea {
  min-width: 24px;
  align-items: center;
  justify-content: center;
  display: flex;
}