@import "../../../stylesheets/rds-common.css";
@import "../../../stylesheets/rds-variable.css";

.socialMediaContainer {
  display: flex;
  flex-direction: column;
  width: fit-content;
  background-color: var(--color-gray-black);

}

.socialMediaContainer ul {
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 0;
  margin-bottom: 0;

}

.socialMediaContainer li{
  margin-right: 8px;
  color: var(--color-light-gray);

}
.socialMediaContainer li a{
  color: var(--color-light-gray);

}
.socialMediaContainer li:last-of-type {
  margin-right: 0;
}

.socialMediaContainer .heading {
  color: var(--shades-lighten-07, rgba(255, 255, 255, 0.67));
  font-size: 12px;
  font-weight: 400;
  line-height: 24px; /* 200% */
  letter-spacing: 0.5px;
}
