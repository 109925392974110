@import "../../../stylesheets/rds-common.css";
@import "../../../stylesheets/rds-variable.css";

.navbarContainer {
  list-style: none;
  margin: 20px 0 0 0;
  padding: 0;
  background-color: var(--color-gray-black);

}

.navbarContainer li {
  color: var(--shades-lighten-10, rgba(255, 255, 255, 0.96));
  font-size: 16px;
  font-weight: 500;
  line-height: 20px; /* 125% */
  letter-spacing: 0.15px;
  text-decoration-line: underline;
}

.navbarContainer li a {
  color: var(--color-light-gray);
}

.navbarContainer li:last-of-type {
  margin-bottom: 0;
}

.navLinksFooter {
  font-size: 14px;
  line-height: 27px;
  font-weight: 400;
}

.mobileHr {
  display: none;
  background: var(--neutral-600, #626262);
  height: 1px;
  width: 100%;
}

@media screen and (min-width: 768px) {
  .navbarContainer li {
    margin: 0 20px 0 0;
  }

  .navbarContainer li:last-of-type {
    margin-right: 0;
  }

  .navbarContainer svg {
    display: none;
  }
}

@media (max-width: 768px) {
  .navbarContainer {
    width: 100%;
  }
  .navbarContainer {
    margin: 0;
  }
  .mobileHr {
    display: inline-block;
  }
  .navbarContainer li:last-of-type div {
    display: none;
  }
}
