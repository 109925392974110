@import "./rds-variable.css";

.wrapper {
  background: #ffffff;
  border: 1px solid #1212121f;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 32px 8px;
  flex: 1 0 0;
}

.wrapper.category {
  height: 88px;
  margin-top: 10px;
}

.wrapper.filter {
  height: 64px;
  margin-top: 8px;
}

.wrapper:hover {
  cursor: pointer;
}

.wrapper.selected {
  border: 1px solid var(--brand-primary-600);
}

.label {
  color: var(--shades-10);
  text-align: center;
}
