@import "./rds-variable.css";
@import "./rds-common.css";

:root {
  font-feature-settings: "clig" off, "liga" off;
  /* font-family: roboto-regular; */
}

.businessName,
.companyNameUrl,
.companyTelephoneUrl {
  text-decoration: none;
}

.city {
  color: var(--rds-color-text-quaternary);
}

.serviceStateLabel {
  color: var(--color-gray);
}

.companyDetailsContainer {
  background-color: var(--neutral-050);
}

.companyNameUrl {
  color: var(--rds-color-text-primary);
}

.companyTelephoneUrl {
  color: var(--rds-color-text-secondary);
}
