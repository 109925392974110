@import './rds-variable.css'; 
@import './rds-common.css'; 

.rds-external-system-message-wrapper {
    display:flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    height: 36px;
    font-weight: bold;
    color: var(--color-white);
    background-color: var(--brand-primary-600);
    text-wrap:balance;
}

.rds-external-system-message-wrapper  a{
    color: var(--color-white);
    font-weight: 500;
    margin-left: 10px;
}

@media only screen and (max-width:767px){
    .rds-external-system-message-wrapper{
        font-size:12px;
        padding: 4px;
    } 

    .rds-external-system-message-wrapper span{
        text-align:center;
    }
}
