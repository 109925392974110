.sitemapWrapper {
    min-height: 500px;
}

.accentText {
    color: var(--color-gray-variant1);
}

.heading {
    font-size: 2rem;
    font-family: "roboto-medium";
    margin: 2rem 0;
}

.sitemap {
    font-size: 1.2rem;
}

.sitemap ul {
    list-style: none;
}

.sitemap a {
    color: var(--color-link-blue);
}

.sitemap a:hover {
    color: var(--color-link-blue);
}

.sitemap li::before {
    content: "\2022";
    color: var(--color-link-blue);
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
}