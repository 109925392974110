@import "./rds-variable.css";

.rds-book-service-form-service-label {
  color: var(--color-gray-black);
  font-family: var(--font-regular);
  font-size: 16px;
  font-style: normal;
  line-height: 24px;
}

.rds-book-service-form-subHeading {
  color: var(--color-gray-black);
  font-size: 1rem;
  font-style: normal;
  line-height: 8px;
  text-align: center;
}

.rds-book-service-form-button {
  display: flex;
  justify-content: flex-end;
  margin-right: 1rem;
}

@media only screen and (max-width: 768px) {
  .rds-book-service-form-subHeading {
    line-height: inherit;
  }

  .rds-book-service-form-button {
    margin-right: 0;
  }
}
