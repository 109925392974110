@import "../stylesheets/rds-variable.css";
@import "../stylesheets/rds-common.css";

.featureImage {
  height: auto;
  width: 100%;
  object-fit: cover;
  aspect-ratio: 2 / 1;
}

.category,
.more {
  color: var(--brand-primary-600);
  cursor: pointer;
  text-transform: uppercase;
}

.category:not(:last-child)::after {
  content: "|";
  color: var(--neutral-200, #cdcdcd);
  padding: 0 8px;
}

.extras,
.checkbox {
  display: none;
}

.checkbox:checked ~ .extras {
  display: inline-block;
}

.checkbox:checked ~ .more {
  display: none;
}

.heading {
  color: var(--rds-color-text-primary);
}

.subHeading {
  color: var(--rds-color-text-tertiary);
}

.footNote {
  color: var(--rds-color-text-tertiary);
  text-transform: capitalize;
}
