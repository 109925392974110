
.privacyPolicy{
 width: 100%;
}

.privacyTitle h1 {
  color: #333;
  font-family: inherit;
  font-style: normal;
  font-weight: 700;
  line-height: 56px;
  letter-spacing: -0.5px;
  text-align: center;
  margin-bottom:0;
}

.privacyContent {
  color: #000;
  font-family: inherit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  padding:0 16px;
}

.privacyPolicyDescription {
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
}

.privacyPolicyDescription:last-of-type {
  margin-bottom: .5rem !important;
}

.privacyPolicyHeading {
  line-height: 24px;
  font-weight: bold;
}

@media only screen and (min-width: 768px) {
  .privacyTitle h1 {
    color: #333;
    font-family: inherit;
    font-style: normal;
    font-weight: 700;
    line-height: 56px;
    letter-spacing: -0.5px;
    text-align: left;
  }

  .privacyContent {
    color: #000;
    font-family: inherit;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
    padding:0;
  }

  .privacyPolicyDescription {
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
  }
  .privacyPolicyHeading {
    line-height: 24px;
    font-weight: bold;
  }
}
