@import '../../../stylesheets/rds-variable.css';

.dropDownContainer {
  background-color: var(--color-white);
  border-radius: 0 0 4px 4px;
  border: 1px solid rgba(0, 0, 0, .3);
  display: none;
  position: absolute;
  padding: 1.3rem 1rem;
  right: 0;
  left: auto;
  top: 40px;
  cursor: default;
  width: 320px;
  z-index: 1033;
}

.dropDownList {
  padding: 0;
  margin: 0;
}

.dropDownList li {
  margin: 0;
}

.dropDownName {
  text-transform: uppercase;
  font-size: var(--font-size-label-2);
  line-height: var(--font-line-height-label-2);
  letter-spacing: var(--font-letter-spacing-label-2);
  color: var(--color-primary);
  padding: 12px;
  list-style: none;
}

.show {
  display: block;
}