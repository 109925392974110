@font-face {
  font-family: roboto-regular;
  src: url("./assets/fonts/Roboto/Roboto-Regular.ttf");
}

@font-face {
  font-family: roboto-light;
  src: url("./assets/fonts/Roboto/Roboto-Light.ttf");
}

@font-face {
  font-family: roboto-bold;
  src: url("./assets/fonts/Roboto/Roboto-Bold.ttf");
}

@font-face {
  font-family: roboto-medium;
  src: url("./assets/fonts/Roboto/Roboto-Medium.ttf");
}

@font-face {
  font-family: "Roboto Slab";
  src: url("./assets/fonts/Roboto/RobotoSlab-Regular.ttf");
}

@font-face {
  font-family: "Roboto Slab";
  src: url("./assets/fonts/Roboto/RobotoSlab-Regular.ttf");
}

@font-face {
  font-family: "Roboto Slab";
  src: url("./assets/fonts/Roboto/RobotoSlab-Regular.ttf");
}

/* global variables */
:root {
  --color-primary: #d5202e;
  --color-gray-black: #222;
  --color-gray-black2: #333;
  --color-white: #fff;
  --color-light-gray: #cacaca;
  --color-hr-line: #e3e3e3;
  --color-gray-variant1: #636363;
  --color-gray-variant2: #f7f7f7;
  --color-link-blue: #0d6efd;
  --color-dark-gray: #333333;
  --shades-darken-10: #121212ED;
  --shades-darken-09: #121212DB;
  --color-black:#000
}

body {
  margin: 0;
  font-family: "roboto-regular", -apple-system, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bold {
  font-weight: bold;
}

ul {
  list-style: none;
}