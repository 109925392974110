@import url("./rds-variable.css");


.overlayCSS {
    z-index: 1033;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--shades-darken-06);
    display: flex;
    align-items: center;
    justify-content: center;
}

.containtStyle {
    background-color: var(--neutral-050);
    display: flex;
    flex-direction: column;
    outline: none;
    max-width: 600px;
}

.modalStyle {
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
}

.closeIconStyle {
    align-self: flex-end;
    padding: 20px;
    cursor: pointer;
}

.titleText {
    margin-top: 40px;
    margin-left: 50px;
    margin-right: 50px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.closebtn {
    align-self: flex-end;
    padding: 20px;
    margin-top: 50px;
    cursor: pointer;
}