@import "./rds-variable.css";
@import "./rds-common.css";

.rds-bodycopy-tray-container {
  width: 100%;
  padding-bottom: 1.5rem !important;
  padding-top: 1.5rem !important;
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  margin-left: auto;
  margin-right: auto;
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  width: 100%;
}
.rds-bodycopy-tray-row {
  --bs-gutter-x: 0;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-left: calc(var(--bs-gutter-x) * -0.5);
  margin-right: calc(var(--bs-gutter-x) * -0.5);
  margin-top: calc(var(--bs-gutter-y) * -1);
  width: 100%;
}

.rds-bodycopy-tray-textalign {
  text-align: left !important;
}

.rds-bodycopy-tray-flex-row-reverse {
  flex-direction: row-reverse !important;
}

.rds-bodycopy-tray-reverse {
  justify-content: center !important;
  display: flex !important;
}

.rds-bodycopy-tray-description {
  margin-bottom: 0;
  margin-top: 0;
  text-align: left !important;
}

.rds-bodycopy-tray-coloumn-top {
  align-self: center !important;
  width: 100%;
}

.rds-bodycopy-tray-coloumn-bottom {
  width: 35%;
  padding: 0.5rem;
}

@media screen and (min-width: 768px) {
  .rds-bodycopy-tray.heading {
    font-size: 0.8rem;
    text-align: left;
  }

  .rds-bodycopy-tray.subHeading {
    font-size: 2.5rem;
    text-align: left;
  }
}
