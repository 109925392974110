.menuOverLay {
    background-color: rgba(0, 0, 0, .5);
    height: 100vh;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    backdrop-filter: blur(8px);
    z-index: 1001;
}

.headerContainer {
    min-height: fit-content;
}

.logoPanel {
    display: flex;
    justify-content: center;
}

.image {
    object-fit: contain;
    width: 130px;
    height: auto;
}

/* position the menu to the right of the screen in desktop view */
.menuContainer {
    display: flex;
    justify-content: flex-end;
}

.noScroll {
    overflow: hidden;
}

@media screen and (min-width: 768px) {
    .logoPanel {
        justify-content: flex-start;
    }

    .image {
        width: 207px;
        height: 65px;
    }
}