.rds-subtitle-1 {
  font-family: var(--font-regular);
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.15px;
}
.rds-subtitle-2 {
  font-family: var(--font-regular);
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
}
.rds-transition-default {
  transition: all 0.2s ease;
}
.rds-button-default {
  position: relative;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  border-radius: 4px;
  font-family: var(--font-regular);
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
}
.rds-button-text {
  position: relative;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  font-family: var(--font-regular);
  font-weight: 400;
}
.rds-outline {
  outline: none;
}
.rds-outline-focus {
  box-shadow: 0 0 0 2px #5e9ed6;
}
.rds-outline-focus-inverted {
  box-shadow: 0 0 0 2px #5e9ed6;
}
.rds-outline-focus-not-visible {
  outline: none;
}
.rds-btn-icon,
.rds-btn,
.rds-btn-text {
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
}
.rds-btn-icon:disabled,
.rds-btn:disabled,
.rds-btn-text:disabled {
  opacity: 0.25;
  cursor: not-allowed;
}
.rds-button-default {
  position: relative;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  border-radius: 4px;
  font-family: var(--font-regular);
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
}
.rds-input-checkbox input:disabled {
  opacity: 0.25;
  cursor: not-allowed;
}
.rds-select-menu input:disabled {
  opacity: 0.25;
  cursor: not-allowed;
}
.react-rds button,
.rds button {
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
}
.react-rds button:disabled,
.rds button:disabled {
  opacity: 0.25;
  cursor: not-allowed;
}
.react-rds input:disabled,
.rds input:disabled,
.react-rds textarea:disabled,
.rds textarea:disabled,
.react-rds button:disabled,
.rds button:disabled {
  opacity: 0.25;
  cursor: not-allowed;
}
.rds-btn {
  position: relative;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  border-radius: 4px;
  font-family: var(--font-regular);
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  outline: none;
  transition: all 0.2s ease;
  padding: 16px 24px;
  min-width: 124px;
}
.rds-btn-full-width {
  width: 100%;
}
.rds-btn span i {
  font-size: 16px;
  line-height: 0.8125;
}
.rds-btn span i.leading {
  padding-right: 8px;
}
.rds-btn span i.trailing {
  padding-left: 8px;
}
.rds-btn.buttonLoading span {
  visibility: hidden;
  opacity: 0;
}
.rds-btn.buttonLoading::after {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 4px solid transparent;
  border-top-color: #ffffff;
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}
.rds-btn:focused {
  box-shadow: 0 0 0 2px #5e9ed6;
}
.rds-btn:focus:not(:focus-visible) {
  outline: none;
}
.rds-btn:focus-visible {
  box-shadow: 0 0 0 2px #5e9ed6;
}
.rds-btn.primary {
  color: rgba(255, 255, 255, 0.96);
  background: #d40029;
  border: 1px solid #d40029;
}
.rds-btn.primary:hover {
  background: #f01839;
  border: 1px solid #f01839;
}
.rds-btn.primary:active {
  background: #aa001e;
  border: 1px solid #aa001e;
}
.rds-btn.primary:disabled:hover {
  background: #d40029;
  border: 1px solid #d40029;
}
.rds-btn.secondary {
  color: rgba(18, 18, 18, 0.86);
  background: #ffffff;
  border: 1px solid #333333;
}
.rds-btn.secondary.bold {
  color: #d5202e;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  border: none;
  border-radius: 4px;
}
.rds-btn.secondary:hover {
  background: #e1e1e1;
}
.rds-btn.secondary:active {
  background: #cdcdcd;
}
.rds-btn.secondary:disabled:hover {
  background: #ffffff;
}
.rds-btn.secondary.buttonLoading::after {
  border-top-color: #333333;
}
.rds-btn.tertiary {
  color: #d40029;
  background: #ffffff;
  border: 1px solid #d40029;
}
.rds-btn.tertiary:hover {
  background: #fff0f0;
}
.rds-btn.tertiary:active {
  background: #ffc8c8;
}
.rds-btn.tertiary:disabled:hover {
  background: #ffffff;
}
.rds-btn.tertiary.buttonLoading::after {
  border-top-color: #d40029;
}
.rds-btn.inverted {
  color: #d40029;
  background: #ffffff;
  border: 1px solid #ffffff;
}
.rds-btn.inverted:hover {
  background: #fff0f0;
}
.rds-btn.inverted:focused {
  box-shadow: 0 0 0 2px #5e9ed6;
}
.rds-btn.inverted:focus:not(:focus-visible) {
  outline: none;
}
.rds-btn.inverted:focus-visible {
  box-shadow: 0 0 0 2px #5e9ed6;
}
.rds-btn.inverted:active {
  background: #ffc8c8;
}
.rds-btn.inverted:disabled:hover {
  background: #ffffff;
}
.rds-btn.inverted.buttonLoading::after {
  border-top-color: #d40029;
}
.rds-btn.expanded {
  display: block;
  width: 100%;
}
@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }
  to {
    transform: rotate(1turn);
  }
}
a.rds-btn-icon,
button.rds-btn-icon {
  position: relative;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  border-radius: 4px;
  font-family: var(--font-regular);
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  outline: none;
  transition: all 0.2s ease;
  color: rgba(18, 18, 18, 0.86);
}
a.rds-btn-icon.rds-btn-selected,
button.rds-btn-icon.rds-btn-selected {
  background: #cdcdcd;
}
a.rds-btn-icon i,
button.rds-btn-icon i {
  text-align: center;
}
a.rds-btn-icon i.fa-xmark,
button.rds-btn-icon i.fa-xmark {
  box-sizing: unset !important;
}
a.rds-btn-icon.large i,
button.rds-btn-icon.large i {
  font-size: 24px;
  padding: 8px;
  width: 24px;
  height: 24px;
}
a.rds-btn-icon.small i,
button.rds-btn-icon.small i {
  font-size: 16px;
  padding: 8px;
  width: 16px;
  height: 16px;
}
a.rds-btn-icon:hover,
button.rds-btn-icon:hover {
  background: #e1e1e1;
}
a.rds-btn-icon:focus:not(:focus-visible),
button.rds-btn-icon:focus:not(:focus-visible) {
  outline: none;
}
a.rds-btn-icon:focus-visible,
button.rds-btn-icon:focus-visible {
  box-shadow: 0 0 0 2px #5e9ed6;
}
a.rds-btn-icon:active,
button.rds-btn-icon:active {
  background: #cdcdcd;
}
a.rds-btn-icon:disabled:hover,
button.rds-btn-icon:disabled:hover {
  background: none;
}
a.rds-btn-icon.primary,
button.rds-btn-icon.primary {
  color: rgba(255, 255, 255, 0.96);
  background: #d40029;
}
a.rds-btn-icon.primary:hover,
button.rds-btn-icon.primary:hover {
  background: #f01839;
}
a.rds-btn-icon.primary:active,
button.rds-btn-icon.primary:active {
  background: #aa001e;
}
a.rds-btn-icon.primary:disabled:hover,
button.rds-btn-icon.primary:disabled:hover {
  background: #d40029;
  border: 1px solid #d40029;
}
a.rds-btn-text,
button.rds-btn-text {
  position: relative;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  border-radius: 4px;
  font-family: var(--font-regular);
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  outline: none;
  transition: all 0.2s ease;
  padding: 8px;
}
a.rds-btn-text i,
button.rds-btn-text i {
  font-size: 16px;
  line-height: 0.8125;
}
a.rds-btn-text i.leading,
button.rds-btn-text i.leading {
  padding-right: 8px;
}
a.rds-btn-text i.trailing,
button.rds-btn-text i.trailing {
  padding-left: 8px;
}
a.rds-btn-text:focused,
button.rds-btn-text:focused {
  box-shadow: 0 0 0 2px #5e9ed6;
}
a.rds-btn-text:focus:not(:focus-visible),
button.rds-btn-text:focus:not(:focus-visible) {
  outline: none;
}
a.rds-btn-text:focus-visible,
button.rds-btn-text:focus-visible {
  box-shadow: 0 0 0 2px #5e9ed6;
}
a.rds-btn-text.primary,
button.rds-btn-text.primary {
  color: #d40029;
  background: none;
}
a.rds-btn-text.primary:hover,
button.rds-btn-text.primary:hover {
  background: #fff0f0;
}
a.rds-btn-text.primary:active,
button.rds-btn-text.primary:active {
  background: #ffc8c8;
}
a.rds-btn-text.primary:disabled:hover,
button.rds-btn-text.primary:disabled:hover {
  background: none;
}
a.rds-btn-text.secondary,
button.rds-btn-text.secondary {
  color: rgba(18, 18, 18, 0.86);
}
a.rds-btn-text.secondary:hover,
button.rds-btn-text.secondary:hover {
  background: #e1e1e1;
}
a.rds-btn-text.secondary:active,
button.rds-btn-text.secondary:active {
  background: #cdcdcd;
}
a.rds-btn-text.secondary:disabled:hover,
button.rds-btn-text.secondary:disabled:hover {
  background: none;
}
