.subMenuContainer {
  background-color: var(--color-white);
  position: absolute;
  width: 100%;
  right: -100%;
  padding: 1rem 1rem 0 1rem;
  overflow-x: hidden;
  overflow-y: auto;
}

.subMenuList {
  padding-left: 1rem;
}

.menuSectionHeading {
  font-size: 0.7rem;
  font-weight: 600;
  color: var(--color-primary);
  text-transform: uppercase;
  letter-spacing: 1px;
}
