@import "./rds-variable.css";

.tabSwitchContainer {
  width: 100%;
}

.tabSwitchContent {
  display: flex;
  height: fit-content;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-bottom: 1px solid var(--neutral-100, #e1e1e1);
  background-color: var(--shades-lighten-08, rgba(255, 255, 255, 0.79));
  margin-top: 0;
  padding-top: 1rem;
}

.tabItemRow {
  display: flex;
  max-width: 1248px;
  padding: 0px 24px;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
}

.tabItems {
  display: flex;
  align-items: flex-start;
  color: var(--typography-default-primary, rgba(18, 18, 18, 0.93));
  font-size: 16px;
  font-family: var(--font-medium);
  font-style: normal;
  padding: 0;
  font-weight: 500;
  line-height: 20px; /* 125% */
  /* 100% */
  letter-spacing: 0.15px;
  cursor: pointer;
}
.tabContainer {
  padding-bottom: 11px;
}
.tabItemsSelected {
  display: flex;
  align-items: flex-start;
  font-weight: 800;
  border-bottom: 4px solid var(--color-primary);
  padding-bottom: 7px;
}

.sticky-tab {
  position: -webkit-sticky;
  position: sticky;
  top: 50px;
  z-index: 1000;
  background-color: #fff;
}

.dropDownContainer {
  display: flex;
  height: 64px;
  align-items: center;
  flex-shrink: 0;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
  border-bottom: 1px solid var(--shades-darken-02, rgba(18, 18, 18, 0.12));
  background: var(--shades-lighten-08, rgba(255, 255, 255, 0.79));
  position: sticky;
  top: 0;
  z-index: 999;
  left: 0;
  right: 0;
  padding: 0 12px;
}

.dropDownSelect {
  color: rgba(18, 18, 18, 0.93);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.15px;
  border: none;
  width: 100%;
  outline: none;
}
