@import "./rds-variable.css";

.rds-maintenance-contact-tray-heading {
  color: var(--color-white);
  font-size: 2.5rem;
  font-style: normal;
  font-weight: bold;
  line-height: 48px;
  letter-spacing: 1px;
  text-align: center;
}

.rds-maintenance-contact-tray-description {
  margin-bottom: 1rem;
  margin-top: 0;
}

.rds-maintenance-contact-tray-btn {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
}

.rds-maintenance-contact-tray-right-content {
  background-color: var(--color-white);
}

.rds-maintenance-contact-tray-subHeading {
  color: var(--color-white);
  font-family: var(--font-bold);
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  text-align: center;
}

.rds-maintenance-contact-tray-form-heading {
  color: var(--color-primary);
  text-transform: uppercase;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: bold;
  line-height: 16px;
  letter-spacing: 1px;
  text-align: center;
}

.rds-maintenance-contact-tray-form-sectionHeading {
  color: var(--color-gray-black);
  font-family: var(--font-bold);
  font-size: 32px;
  font-style: normal;
  font-weight: bold;
  line-height: 36px;
  text-align: center;
}

.rds-maintenance-contact-tray-form-subHeading {
  color: var(--color-gray-black);
  font-size: 1rem;
  font-style: normal;
  line-height: 8px;
  text-align: center;
}

@media screen and (min-width: 768px) {
  .rds-maintenance-contact-tray.heading {
    font-size: 0.8rem;
    text-align: left;
  }

  .rds-maintenance-contact-tray.subHeading {
    font-size: 2.5rem;
    text-align: left;
  }
}

/* //form fields css */

.rds-form-button {
  display: flex;
  justify-content: flex-end;
  margin-right: 1rem;
}
