
#termsAndConditionsTitle h1 {
    color: var(--color-gray-black);
    font-family: inherit;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: -0.5px;
    text-align: center;
    margin-bottom: 0;
  }
  
  .termsAndConditionsContent {
    color: var(--color-black);
    font-family: inherit;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
    padding: 0 16px 8px 16px;
  }
  
  .termsAndConditionsBody {
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
  }
  
  .termsAndConditionsBody:last-of-type {
    margin-bottom: 0.5rem !important;
  }
  
  .termsAndConditionsBody ul li {
    list-style-type: none;
  }
  
  .termsAndConditionsBody ul li a {
    color: var(--color-gray-black);
  }
  
  .termsAndConditionsHeading {
    line-height: 24px;
    font-weight: bold;
    font-size: 18px;
    font-family: "roboto-bold";
  }
  
  /* Desktop styles */
  
  @media only screen and (min-width: 768px) {
    #termsAndConditionsTitle h1 {
      color: var(--color-gray-black2);
      font-family: inherit;
      font-style: normal;
      font-weight: 700;
      line-height: 56px;
      letter-spacing: -0.5px;
      text-align: left;
      margin-bottom: 0;
    }
  
    .termsAndConditionsContent {
      color: var(--color-black);
      font-family: inherit;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.5px;
      padding: 0 8px 24px 8px;
    }
  
    .termsAndConditionsBody {
      font-style: normal;
      font-size: 16px;
      line-height: 24px;
    }
    .terms_And_Conditions_Heading {
      line-height: 24px;
      font-weight: bold;
      font-family: "roboto-bold";
    }
  }
  