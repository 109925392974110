.heading {
  color: var(--color-primary);
  font-size: 0.8rem;
  text-transform: uppercase;
  font-weight: bold;
}

.subHeading {
  color: var(--color-gray-black);
  font-family: "roboto-bold";
  font-size: 2.5rem;
}

.description {
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
};
