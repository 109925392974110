.faqTitle h1 {
  color: rgba(18, 18, 18, 0.93);
  font-family: inherit;
  font-style: normal;
  font-weight: 700;
  line-height: 48px;
  text-align: center;
}

.faqTray {
  padding: 32px 0;
}

@media only screen and (min-width: 768px) {
  .faqTitle h1 {
    color: rgba(18, 18, 18, 0.93);
    font-family: inherit;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
    text-align: left;
  }

  .faqTray {
    padding: 48px 0;
  }
}
