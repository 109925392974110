@import "./rds-variable.css";

/* General applied to all */
/* * {
  font-style: var(--font-style);
  font-feature-settings: var(--font-feature-off);
  font-family: var(--font-family-Roboto);
 } */

.rds-font-title-1 {
  font-family: inherit;
  font-style: var(--font-style);
  font-feature-settings: var(--font-feature-off);
  /*  */
  font-size: var(--font-size-title-1);
  font-weight: var(--font-weight-bold);
  line-height: var(--font-line-height-title-1);
  letter-spacing: var(--font-letter-spacing-title-1);
  text-align: left;
}

.rds-font-title-2 {
  font-family: inherit;
  font-style: var(--font-style);
  font-feature-settings: var(--font-feature-off);
  /*  */
  font-size: var(--font-size-title-2);
  font-weight: var(--font-weight-bold);
  line-height: var(--font-line-height-title-2);
  letter-spacing: var(--font-letter-spacing-title-2);
  text-align: left;
}

.rds-font-title-3 {
  font-family: inherit;
  font-style: var(--font-style);
  font-feature-settings: var(--font-feature-off);
  /*  */
  font-size: var(--font-size-title-3);
  font-weight: var(--font-weight-bold);
  line-height: var(--font-line-height-title-3);
  letter-spacing: var(--font-letter-spacing-title-3);
  text-align: left;
}

.rds-font-title-4 {
  font-family: inherit;
  font-style: var(--font-style);
  font-feature-settings: var(--font-feature-off);
  /*  */
  font-size: var(--font-size-title-4);
  font-weight: var(--font-weight-bold);
  line-height: var(--font-line-height-title-4);
  letter-spacing: var(--font-letter-spacing-title-4);
  text-align: left;
}

.rds-font-title-5 {
  font-family: inherit;
  font-style: var(--font-style);
  font-feature-settings: var(--font-feature-off);
  /*  */
  font-size: var(--font-size-title-5);
  font-weight: var(--font-weight-extra-bold);
  line-height: var(--font-line-height-title-5);
  letter-spacing: var(--font-letter-spacing-title-5);
  text-align: left;
}

.rds-font-product-title-1 {
  font-family: inherit;
  font-style: var(--font-style);
  font-feature-settings: var(--font-feature-off);
  /*  */
  font-size: var(--font-size-product-title-1);
  font-weight: var(--font-weight-medium);
  line-height: var(--font-line-height-product-title-1);
  letter-spacing: var(--font-letter-spacing-product-title-1);
  text-align: left;
}

.rds-font-product-title-2 {
  font-family: inherit;
  font-style: var(--font-style);
  font-feature-settings: var(--font-feature-off);
  /*  */
  font-size: var(--font-size-product-title-2);
  font-weight: var(--font-weight-medium);
  line-height: var(--font-line-height-product-title-2);
  letter-spacing: var(--font-letter-spacing-product-title-2);
  text-align: left;
}

/* ~rds-font-product-title-3 */
.rds-font-product-title-3 {
  font-family: inherit;
  font-size: var(--font-size-product-title-2);
  font-style: var(--font-style);
  font-weight: var(--font-weight-medium);
  line-height: var(--font-line-height-product-title-2); /* 125% */
  letter-spacing: var(--font-letter-spacing-product-title-2);
}

/* ~rds-font-product-title-4 */
.rds-font-product-title-4 {
  font-family: inherit;
  font-style: var(--font-style);
  font-feature-settings: var(--font-feature-off);
  /*  */
  font-size: var(--font-size-product-title-4);
  font-weight: var(--font-weight-bold);
  line-height: var(--font-line-height-product-title-4);
  letter-spacing: var(--font-letter-spacing-product-title-4);
  text-align: left;
  text-decoration-line: underline;
}

.rds-font-subtitle-1 {
  font-family: inherit;
  font-style: var(--font-style);
  font-feature-settings: var(--font-feature-off);
  /*  */
  font-size: var(--font-size-subtitle-1);
  font-weight: var(--font-weight-medium);
  line-height: var(--font-line-height-subtitle-1);
  letter-spacing: var(--font-letter-spacing-subtitle-1);
  text-align: left;
}

.rds-font-subtitle-2 {
  font-family: inherit;
  font-style: var(--font-style);
  font-feature-settings: var(--font-feature-off);
  /*  */
  font-size: var(--font-size-subtitle-2);
  font-weight: var(--font-weight-medium);
  line-height: var(--font-line-height-subtitle-2);
  letter-spacing: var(--font-letter-spacing-subtitle-2);
  text-align: left;
}

.rds-font-body-1 {
  font-family: inherit;
  font-style: var(--font-style);
  font-feature-settings: var(--font-feature-off);
  /*  */
  font-size: var(--font-size-body-1);
  font-weight: var(--font-weight-regular);
  line-height: var(--font-line-height-body-1);
  letter-spacing: var(--font-letter-spacing-body-1);
  text-align: left;
}

.rds-font-body-2 {
  font-family: inherit;
  font-style: var(--font-style);
  font-feature-settings: var(--font-feature-off);
  /*  */
  font-size: var(--font-size-body-1);
  font-size: 14px;
  font-weight: var(--font-weight-regular);
  line-height: var(--font-line-height-body-1);
  line-height: 20px;
  letter-spacing: var(--font-letter-spacing-body-1);
  letter-spacing: 0.25px;
  text-align: left;
}

.rds-font-button {
  font-family: inherit;
  font-style: var(--font-style);
  font-feature-settings: var(--font-feature-off);
  /*  */
  font-size: var(--font-size-button);
  font-size: 14px;
  font-weight: var(--font-weight-medium);
  line-height: var(--font-line-height-button);
  line-height: 20px;
  letter-spacing: var(--font-letter-spacing-button);
  letter-spacing: 0.25px;
  text-align: left;
}

.rds-font-caption {
  font-family: inherit;
  font-style: var(--font-style);
  font-feature-settings: var(--font-feature-off);
  /*  */
  font-size: var(--font-size-button);
  font-size: 12px;
  font-weight: var(--font-weight-light);
  line-height: var(--font-line-height-button);
  line-height: 16px;
  letter-spacing: var(--font-letter-spacing-button);
  letter-spacing: 0.4000000059604645px;
  text-align: left;
}

/* ~.rds-font-label */
.rds-font-label {
  font-family: inherit;
  font-style: var(--font-style);
  font-feature-settings: var(--font-feature-off);
  /*  */
  font-size: var(--font-size-label);
  font-weight: var(--font-weight-medium);
  line-height: var(--font-line-height-label);
  letter-spacing: var(--font-letter-spacing-label);
  text-align: center;
  text-transform: uppercase;
  color: var(--brand-primary-600);
}

/* ~rds-font-label-2 */
.rds-font-label-2 {
  font-family: inherit;
  font-style: var(--font-style);
  font-feature-settings: var(--font-feature-off);
  /*  */
  font-size: var(--font-size-label-2);
  font-weight: var(--font-weight-medium);
  line-height: var(--font-line-height-label-2);
  letter-spacing: var(--font-letter-spacing-label-2);
  text-transform: uppercase;
}

h2.rds-section-heading {
  color: var(--color-primary);
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  font-family: var(--font-medium);
  line-height: 16px;
  letter-spacing: 1.25px;
  text-align: center;
}

h1.rds-heading {
  color: var(--color-gray-black);
  font-family: var(--font-medium);
  font-size: 40px;
  font-weight: 500;
  line-height: 48px;
  margin-bottom: 0.5rem;
  margin-top: 0;
  text-align: center;
}

h3.rds-heading {
  color: var(--color-gray-black);
  font-family: var(--font-medium);
  font-size: 40px;
  font-weight: 500;
  line-height: 48px;
  margin-bottom: 0.5rem;
  margin-top: 0;
  text-align: center;
}

h4.rds-subHeading {
  color: var(--color-gray-black);
  font-weight: 500;
  font-family: var(--font-medium);
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  margin-bottom: 0.5rem;
  margin-top: 0;
}

p.rds-description {
  font-weight: 400;
  font-family: var(--font-regular);
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
}

.rds-wrapper {
  padding-top: 2rem;
  border-bottom: 1px solid var(--neutral-100, #E1E1E1);
}

.title {
  color: var(--shades-darken-10);
  text-transform: uppercase;
}

.min-height-65 {
  min-height: 65vh;
}