/*Square cards used for Our Services */
.squareCardContainer {
  border: 1px solid rgba(220, 220, 220, 1);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .4);
}

.cardTitle {
  font-size: 0.8rem;
  font-weight: 600;
  text-align: center;
}

.squareCardContainer a {
  font-size: 0.8rem;
}

@media only screen and (min-width: 1024px) {
  .squareCardContainer {
    min-width: 190px;
  }
}
