.heading {
    color: rgba(18, 18, 18, 0.93);
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
    padding-top: 16px;
    padding-bottom: 8px;
}

.subHeading {
    color: rgba(18, 18, 18, 0.74);
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 32px;
    letter-spacing: 0.18px;
    padding-bottom: 16px;
    sup {
        font-size: 0.6em;
    }
}

.information {
    display: flex;
    justify-content: flex-end;
    color: rgba(18, 18, 18, 0.66);
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.4px;
}

.resultHeading {
    color: var(--shades-darken-10);
    font-feature-settings: 'liga' off, 'clig' off;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 40px;
}

.resultSubHeading {
    padding-top: 8px;
    color: var(--shades-darken-09);
    font-feature-settings: 'liga' off, 'clig' off;
    font-size: 20px;
    font-weight: 500;
    font-style: normal;
    line-height: 24px;
    letter-spacing: 0.15px;
}

.boxStyle {
    display: flex;
}

.containerColor {
    background-color: var(--color-gray-variant2);
}

@media (max-width: 768px) {
    .subHeading {
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0.15px;
    }
}