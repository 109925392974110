@import "./rds-variable.css";
@import "./rds-common.css";

.backgroundImage {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.backgroundOverlay {
  background: var(--shades-darken-07);
  height: 100%;
  width: 100%;
  color: var(--color-white);
}

.image {
  object-fit: contain;
  object-position: center;
  width: 100%;
  height: auto;
}

.state {
  color: var(--color-primary);
}

.sectionHeading {
  color: var(--brand-primary-600);
  text-align: center;
  font-size: 14px; /* TODO: fix with ~rds-font-label */
}

.sectionHeading,
.heading,
.subHeading {
  text-align: left;
}

.link {
  text-decoration: underline;
  color: var(--color-gray-black);
}

.serviceTrayBtn {
  display: flex;
  justify-content: center;
}

@media only screen and (min-width: 768px) {

  .sectionHeading,
  .heading,
  .subHeading {
    text-align: left;
  }

  .serviceTrayBtn {
    justify-content: start;
  }
}