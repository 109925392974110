@import "./rds-variable.css";

.campaignBanner {
  position: relative;
  min-height: 300px;
  height: 100%;
  /* min-height: 336px; */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.formBg {
  background: var(--shades-darken-07);
  border-radius: "5px";
}