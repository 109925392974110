@import "./rds-variable.css";

.wrapper {
  background-color: var(--neutral-050);
  border-width: 1px, 0px, 1px, 0px;
  border-style: solid; 
  border-color: var(--neutral-100, #E1E1E1);
}

.title {
  color: var(--shades-darken-10);
}

.link {
  color: var(--brand-primary-600);
  text-decoration: underline;
  text-underline-offset: 4px;
  cursor: pointer;
}