@import '../../../stylesheets/rds-variable.css';

.menuItems {
  border-bottom: 1px solid var(--color-hr-line);
  padding: 10px 0;
  list-style: none;
}

.dropDownItemSecNav:hover, .menuItemSecNav:hover {
  background-color: var(--shades-lighten-02, rgba(255,255,255,0.07));
}

.dropDownItem, .dropDownItemSecNav {
  border: none;
  font-weight: 500;
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
  position: relative;
  padding: 8px 16px;
  border-radius: 4px;
  justify-content: space-between;
  width: 100%;
  text-decoration: none;
  color: var(--color-gray-black);
  transition: all 100ms ease;
  font-size: 1rem;
}

.dropDownItemSecNav {
  color: var(--color-white);
  padding: 0;
}


.dropDownItemParent, .dropDownItemParentSecNav {
  text-decoration: none;
  color: var(--color-gray-black);
  position: relative;
}

.dropDownItemParentSecNav {
  color: var(--color-white);
  padding: 8px 16px;
}

.menuItem, .menuItemSecNav {
  display: block;
  padding: 8px 16px;
  text-decoration: none;
  color: var(--color-gray-black) !important;
  border-radius: 4px;
  text-align: left;
  font-weight: 500;
  font-size: 1rem;
  transition: all 100ms ease;
}

.menuItemSecNav {
  color: var(--color-white) !important;
}

.dropDownItemParent:hover,
.menuItem:hover,
.dropDownItem:hover {
  color: var(--color-gray-black);
  font-weight: 700;
}

.menuItem:hover,
.dropDownItem:hover {
  background-color: var(--neutral-100);
}

.activeNav {
  border-radius: 4px;
  background-color: var(--neutral-100);
  transition: all 100ms ease;
  font-weight: 700;
}

@media screen and (min-width: 768px) {
  .menuItems {
    margin-right: 17px;
    border-bottom: none;
    padding: 0;
  }

  .menuItems:last-of-type {
    margin-right: 0;
  }
}