@import "./rds-variable.css";

.filtersContainer {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 16px;
    list-style: none;
    margin: 0;
    padding: 0;
}

.filters {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
    background: var(--neutral-100, #e1e1e1);
}

.filterText {
    color: var(--color-gray-black, #121212);
    font-feature-settings: var(--font-feature-settings);
    font-family: var(--font-family-medium);
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.clearAll {
    font-family: var(--font-family-regular);
    font-size: 10px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.4px;
    text-align: left;
    color: var(--brand-primary-600, #D40029);
    font-feature-settings: var(--font-feature-settings);
    text-decoration-line: underline;
    cursor: pointer;
    list-style-type: none;
}

.closeIcon {
    font-family: var(--font-family-medium);
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: var(--color-gray, #333333);
    cursor: pointer;
    padding-left: 8px;
}