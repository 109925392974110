@import "../../../stylesheets/rds-common.css";
@import "../../../stylesheets/rds-variable.css";

.logoContainer {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0;
  background-color: var(--color-gray-black);
}

.logoContainer li {
  margin-right: 10px;
}

@media only screen and (max-width: 768px) {
  .logoContainer img {
    width: 158px;
    height: 58px;
  }
}
