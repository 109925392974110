@import "./rds-common.css";

.icon-tray {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.icon-container {
  width: calc(25% - 20px);
  margin-bottom: 20px;
  text-align: center;
}

.icon-tray.center-align {
  margin-left: auto;
  margin-right: auto;
}

.icon-image {
  width: 72px;
  height: auto;
  cursor: pointer;
}

.icon-heading {
  text-align: center;
  color: var(--color-gray-black);
  font-family: var(--font-bold);
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 0.5rem;
  margin-top: 0;
}

.icon-description {
  margin-top: 5px;
  font-size: 14px;
}

@media only screen and (max-width: 600px) {
  .icon-container {
    width: 100%;
  }
}
