@import "./rds-variable.css";

.rds-services-background .rds-services-heading {
  background-color: var(--color-gray-black);
  text-align: center;
  color: var(--color-white);
  font-size: 0.8rem;
  font-weight: 500;
  line-height: 24px;
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: var(--color-white) !important;
  border-bottom: 1px solid var(--neutral-400, #9e9e9e);
}

.rds-services {
  width: 100%;
}

.rds-services-row {
  width: 100%;
  border-bottom: 1px #121212;
}

.rds-services-main-head-anchor {
  margin-bottom: 5%;
}

.rds-services-head-anchor {
  color: var(--shades-darken-10, rgba(18, 18, 18, 0.93));
  font-feature-settings: "liga" off, "clig" off;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: 24px;
  letter-spacing: 0.15px;
  text-decoration: none;
}

.rds-services-background {
  background: #f7f7f7;
}

.rds-services-main-sub-anchor {
  margin-bottom: 5%;
}
.rds-service-icon {
  cursor: pointer;
}
.rds-services-sub-anchor {
  color: var(--color-gray-black);
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.5px;
  margin-top: 5%;
  margin-bottom: 5%;
  padding-top: 2%;
  padding-bottom: 2%;
}
