.hero-banner-tray {
  position: relative;
  width: 100%;
  min-height: 336px;
  background-repeat:no-repeat;
  background-size:cover;
  background-position:center;
}

.hero-banner-row {
  min-height: 336px;
}

.hero-banner-background {
  background: linear-gradient(90deg, rgba(18, 18, 18, 0.66) 50%, rgba(0,0,0,0) 50%);
}

.banner-logo {
  max-width: 100%;
  height: auto;
  padding: 0;
  object-fit: contain;
}

.content {
  color: var(--color-white);
  font-feature-settings: 'liga' off, 'clig' off;
}

@media only screen and (max-width: 768px) {

  .hero-banner-tray {
    min-height: 300px;
  }

  .hero-banner-row {
    min-height: 300px;
    padding: 32px;
  }

  .hero-banner-background {
    background: rgba(18, 18, 18, 0.66);
  }

  .banner-logo {
    max-width: 100%;
    padding: 15px 18px 0 0
  }
}