@import "./rds-variable.css";

.linkStyle {
  font-size: 1rem;
  color: var(--brand-primary-600);
  font-weight: 400;
  text-decoration: underline;
  cursor: pointer;
}

.sectionTitle {
  font-weight: 600;
  font-size: 1.5rem;
}

.buttonStyle {
  cursor: pointer;
  background-color: var(--brand-primary-600);
  color: var(--color-secondary);
  border: none;
}

@media (max-width: 576px) {
  .sectionTitle {
    font-size: 1.2rem;
  }

  .linkStyle {
    font-size: 0.9rem;
    color: var(--brand-primary-600);
    text-decoration: underline;
    cursor: pointer;
  }
}

.containerBackground {
  background: rgb(247, 247, 247);
}

.cardWrapper {
  display: grid;
  grid-auto-rows: 1fr;
}