@import '../../../stylesheets//rds-variable.css';

.navMenuContainer {
  background-color: var(--color-white);
  position: relative;
  font-size: .85rem;
  width: fit-content;
}

.secondaryNavBg {
  background-color: var(--neutral-800);
}

.navMenuItems {
  list-style: none;
  padding: 0%;
  margin: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
}

@media screen and (min-width: 768px) {
  .navMenuItems {
    flex-direction: row;
    width: auto;
    align-items: center;
  }
}
